:root {
  --greenElement: #86efac;
  --textBackground: #262626;
  --toggleBackground: #2d2d2d;
  --boxBackground: #202020;
  --pTextColor: #a3a3a3;
  --soicalIcon: #a3a3a3;
  --backgroundColor: #121212;
  --toggleElement: #2bc05f;
}
.container {
  text-align: center;
  width: fit-content;
  padding: 20px;
  background-color: var(--toggleBackground);
  border-radius: 10px;
  margin: 20px;
}
.container p {
  text-align: center;
  margin: 0px;
}
.toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  text-align: left;
  top: 8px;
}
.checkbox {
  display: none;
}
.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
}
.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  margin-right: -100%;
  transition: margin 0.3s ease 0s;
}
.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 36px;
  padding: 0;

  line-height: 36px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}
.inner:before {
  content: "";
  padding-left: 10px;
  background-color: var(--toggleElement);
  color: #fff;
}
.inner:after {
  content: "";
  padding-right: 10px;
  background-color: #bbb;
  color: #fff;
  text-align: right;
}
.switch {
  display: block;
  width: 24px;
  margin: 5px; /* background-color: #86efac; */
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;

  border-radius: 20px;
  transition: all 0.3s ease 0s;
}
.checkbox:checked + .label .inner {
  margin-left: 0;
}
.checkbox:checked + .label .switch {
  right: 0px;
}
