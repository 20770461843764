.hydroponics {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  background-color: var(--boxBackground);
}

.hydroponics p {
  font-size: 1.4rem;
  text-align: center;
  line-height: 1.8rem;
  color: var(--pTextColor);
  margin-top: 0;
  padding-bottom: 20px;
}

.sensorValues {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;

  background-color: var(--backgroundColor);
  width: 100%;

  border-radius: 15px;
}

.sensor_label {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  background-color: var(--boxBackground);
  margin: 20px;
  border-radius: 10px;

  font-size: large;
}

.sensor_value {
  font-size: 32px;
  font-weight: bolder;
  margin: 20px;
  color: white;
}
