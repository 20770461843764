.about {
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: var(--boxBackground);
  border-radius: 10px;
  color: white;
}

.iot_icon {
  width: 50px;
  color: white;
}

.borderBox1 {
  padding: 25px;
  margin: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: var(--boxBackground); */
  transition: border-color 1s;
  margin-bottom: 20px;
}

.about h1 {
  font-size: 3rem;
  margin-bottom: 1px;
}

.about p {
  font-size: 1.5rem;
  text-align: center;
  color: var(--pTextColor);
  margin-bottom: 5px;
}

.box2 {
  /* width: 300px; */
  /* height: 3rem; */
  /* padding: 10px; */

  display: flex;
  justify-content: center;
  align-items: center;

  border: 0 solid white;
  border-radius: 10px;
  /* background-color: var(--boxBackground); */
}
.socialMedia {
  display: flex;
  gap: 15px;
  border: 0 solid white;
  border-radius: 10px;
  background-color: var(--boxBackground);
}

.socialMedia a:hover {
  transform: scale(1.1); /* Scale on hover */
}

.socialMedia a:hover svg {
  fill: var(--greenElement); /* Change the fill color on hover */
  stroke: none;
}
.hexTK1 {
  padding: 25px;
  margin: 20px;
  padding-bottom: 40px;
  border-radius: 10px;
  background-color: var(--boxBackground);
  transition: border-color 1s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}
.hexTK1 h1 {
  margin-top: 20px;
  text-align: center;
}

.hexTK1 p {
  height: 50px;
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.8rem;
  color: var(--pTextColor);
  margin-bottom: 1.9rem;
  margin-top: 1.9rem;
  width: 60%;
}

.hextk {
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: row;
}
.hextk a {
  /* width: 30%; */
  background-color: var(--greenElement);
  color: black;
  border-radius: 8px;
  border-width: 0;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 9px 11px;
  text-align: center;
  justify-content: center;
  transition: all 200ms;
}
.hextk a:hover {
  background-color: var(--pTextColor);
}

.aboutHexfuse {
  padding: 25px;
  margin: 20px;
  border-radius: 10px;
  /* background-color: var(--boxBackground); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}
.aboutHexfuse h1 {
  margin-bottom: 0;
}

.aboutHexfuse p {
  width: 60%;
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.8rem;
  color: var(--pTextColor);
  margin-top: 0;
}
@media screen and (max-width: 600px) {
  .aboutHexfuse p {
    width: 300px;
    text-align: justify;
  }

  /* .borderBox {
    padding: 20px;
    padding-top: 30px;
  } */
  /* .borderBox svg {
    height: 15vh;
  } */
}

.hextk {
  padding: 25px;
  margin: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--boxBackground);
  gap: 20px;
  color: var(--pTextColor);

  border-radius: 10px;
}

.hextk p {
  font-size: 1.1rem;
  text-align: center;
  line-height: 1.8rem;
  color: var(--pTextColor);
  margin-top: 0;
  padding-bottom: 20px;
}
