h1 {
  font-size: 2rem;
}

.loginForm {
  width: 100%;
  height: 100vh;
  background-color: var(--backgroundColor);
  color: var(--pTextColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.formContainer {
  width: 30rem;
  margin: 30px;
  padding: 20px;
  background-color: var(--boxBackground);

  border: 2px solid none;
  border-radius: 10px;
  border-bottom: 8px solid var(--greenElement);

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.companyLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin: 30px;
}
.companyLogo h3 {
  margin: 0px;
  font-size: 1.5rem;
}
input {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 10px;

  background-color: transparent;
  border: 2px solid var(--pTextColor);
  border-radius: 5px;
  color: white;
  height: 1.2rem;
  padding: 5px;
}

a {
  color: white;
}

.successPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.successPage h3 {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 0;
}

.persistCheck {
  display: flex;
  align-items: center;
}
.persistCheck input {
  margin: 5px;
}

button {
  height: max-content;
  width: max-content;
  font-size: 1rem;
  padding: 5px;
  margin: 10px 10px 10px 0;

  border-radius: 4px;
  font-family: inherit;
  font-weight: 500;
  color: black;
  background-color: var(--soicalIcon);
  transition: background-color 500ms;
}

button:hover {
  background-color: var(--greenElement);
}
button:active {
  transform: scale(0.95);
  background-color: var(--greenElement);
}

button:disabled {
  background-color: var(--soicalIcon);
  transform: scale(1);
}

.errmsg {
  border: 2px solid rgb(255, 81, 81);
  border-radius: 10px;
  padding: 10px;
}
